import React, { useState, useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { useFirebase } from "../context/SettingAction";
import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Input } from "./ui/input";

const schema = z.object({
  taxPercentage: z.coerce.number(),
  paypalClientId: z.string().optional(),
  redirectURL: z.string().optional(),
  squareLocationId: z.string().optional(),
  squareApplicationId: z.string().optional(),
  squareAccessToken: z.string().optional(),
  stripeSecretKey: z.string().optional(),
  stripePublishableKey: z.string().optional(),
});

function CheckoutSettings({
  taxPercentage,
  paypalClientId,
  redirectURL,
  squareAccessToken,
  squareApplicationId,
  squareLocationId,
  stripeSecretKey,
  stripePublishableKey
}) {
  const [loading, setLoading] = useState(false);
  const { updateCheckoutAction } = useFirebase();

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      taxPercentage,
      paypalClientId,
      redirectURL,
      squareAccessToken,
      squareApplicationId,
      squareLocationId,
      stripeSecretKey,
      stripePublishableKey
    },
  });

  const onSubmitAction = async (data) => {
    setLoading(true);
    await updateCheckoutAction(data);
    setLoading(false);
    toast.success(`Updated Checkout Settings`);
  };
  useEffect(() => {

  }, [paypalClientId, redirectURL, squareAccessToken, squareApplicationId, squareLocationId, stripeSecretKey, stripePublishableKey])
  return (
    <Card className="max-w-[600px] sm:w-full">
      <CardHeader>
        <CardTitle>Checkout Preferences</CardTitle>
        <CardDescription>Manage your checkout preferences</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-6">

        <label htmlFor="" className="font-bold text-medium label-orange-title">
          Tax
        </label>
        <form
          id="checkout_form_paypal"
          onSubmit={form.handleSubmit(onSubmitAction)}
          className="grid gap-6"
        >
          <div className="flex flex-col space-y-2">
            <div className="text-sm font-medium formlabel-fullwidth-title">Tax Percentage</div>
            <Input
              {...form.register("taxPercentage")}
              min={0}
              type="number"
              step="0.01"
              pattern="^\d*(\.\d{0,2})?$"
            />
          </div>
        </form>

        <label htmlFor="" className="font-bold text-medium label-orange-title">
          Stripe
        </label>
        <form
          id="checkout_form_paypal"
          onSubmit={form.handleSubmit(onSubmitAction)}
          className="grid gap-6"
        >
          <div className="flex flex-col space-y-2">
            <div className="text-sm font-medium formlabel-fullwidth-title">Secret key</div>
            <Input {...form.register("stripeSecretKey")} />
          </div>
          <div className="flex flex-col space-y-2">
            <div className="text-sm font-medium formlabel-fullwidth-title">
              Publishable key
            </div>
            <Input {...form.register("stripePublishableKey")} />
          </div>
        </form>
        <label htmlFor="" className="font-bold text-medium label-orange-title">
          Paypal
        </label>
        <form
          id="checkout_form_paypal"
          onSubmit={form.handleSubmit(onSubmitAction)}
          className="grid gap-6"
        >
          <div className="flex flex-col space-y-2">
            <div className="text-sm font-medium formlabel-fullwidth-title">Paypal Client ID</div>
            <Input {...form.register("paypalClientId")} />
          </div>
          <div className="flex flex-col space-y-2">
            <div className="text-sm font-medium formlabel-fullwidth-title">
              Redirect After Purchase
            </div>
            <Input {...form.register("redirectURL")} />
          </div>
        </form>
        <label htmlFor="" className="font-bold text-medium label-orange-title">
          Square
        </label>
        <form
          id="checkout_form_square"
          onSubmit={form.handleSubmit(onSubmitAction)}
          className="grid gap-6"
        >
          <div className="flex flex-col space-y-2">
            <div className="text-sm font-medium formlabel-fullwidth-title">Square Client ID</div>
            <Input {...form.register("squareApplicationId")} />
          </div>
          <div className="flex flex-col space-y-2">
            <div className="text-sm font-medium formlabel-fullwidth-title">Square Access Token</div>
            <Input {...form.register("squareAccessToken")} />
          </div>
          <div className="flex flex-col space-y-2">
            <div className="text-sm font-medium formlabel-fullwidth-title">Square Location ID</div>
            <Input {...form.register("squareLocationId")} />
          </div>
        </form>
      </CardContent>
      <CardFooter>
        <Button
          variant="outline"
          type="submit"
          form="checkout_form_paypal"
          className="w-full save-currency-btn"
        >
          {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Save Preferences
        </Button>
      </CardFooter>
    </Card>
  );
}

export default CheckoutSettings;
